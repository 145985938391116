// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the custom CSS file for Footer
import mmdLogo from './mmdlogo.jpg';

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-logo">
      <img src={mmdLogo} alt="MMD Logo"/>
      </div>

      <div className="footer-links">
        <div className="route-links">
          <Link to="/">Home</Link>
          <Link to="/members">Members</Link>
          <Link to="/aboutus">About Us</Link>
          <Link to="/ourservices">Our Services</Link>
          <Link to="/servicecenter">Service Centre</Link>
          <Link to="/contactus">Contact Us</Link>
          {/* Add more route links as needed */}
        </div>
        <div className="social-media-links">
          {/* Add social media icons/links here 
          <a href="https://www.facebook.com/mmdservices" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i> Facebook
          </a>
          <a href="https://www.twitter.com/mmdservices" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i> Twitter
          </a>
           Add more social media links as needed */}
        </div>
      </div>

      <div className="footer-address">
        <h4>REGISTERED OFFICE</h4>
        <p>MMD India Services Pvt Ltd</p>
        <p>Office unit - 407, 4th Floor, Suneja Tower 2, Plot no. - 12</p>
        <p>District center - Janakpuri - New Delhi-110058</p><br></br>
        <h4>REGIONAL OFFICE</h4>
        <p>MMD India Services Pvt Ltd</p>
        <p>3rd Floor Agarwal House, 2, St. George's Gate Road</p>
        <p>Kolkata-700022</p>
      
      </div>

      <div className="footer-copyright">
        <p>&copy; 2023 MMD India Services Pvt Ltd. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
