// OurServices.js
import React from 'react';
import './OurServices.css'; // Import the custom CSS file for OurServices

const OurServices = () => {
  return (
    <div className="our-services-container">
      <div className="intro">
        <h2>Our Services</h2>
        <p>
          As a mobile phone repairing business, we offer a wide range of services to address various issues and needs related to mobile devices. Our services are designed to ensure that our customers' mobile phones are repaired efficiently, quickly, and with the utmost care.Our mission is to provide reliable and professional mobile phone repair services to our customers. We understand the importance of mobile devices in our daily lives, and we strive to ensure that our customers' devices are back in excellent working condition as quickly as possible. Whether it's a simple screen replacement or a complex motherboard repair, you can trust us to handle your mobile phone with expertise and care.
        </p>
      </div>

      <div className="services">
        <div className="service-container">
          <h3>Diagnostics</h3>
          <p>We start by performing a thorough assessment of the mobile phone to identify the root cause of the problem. Our skilled technicians use advanced tools and techniques to pinpoint the issue accurately.</p>
        </div>

        <div className="service-container">
          <h3>Screen Repair and Replacement</h3>
          <p>Cracked or broken screens are one of the most common problems with mobile phones. We offer screen repair and replacement services for various models, ensuring that the device looks and functions like new.</p>
        </div>

        <div className="service-container">
          <h3>Battery Replacement</h3>
          <p>If your phone's battery is draining quickly or not holding a charge, we can replace it with a genuine and high-quality battery to restore your phone's battery life.</p>
        </div>

        <div className="service-container">
          <h3>Charging Port Repair</h3>
          <p>Issues with the charging port can prevent your phone from charging correctly. We can repair or replace the charging port, ensuring your device charges properly.</p>
        </div>

        <div className="service-container">
          <h3>Water Damage Repair</h3>
          <p>Accidental exposure to water can cause significant damage to mobile phones. Our technicians are experienced in handling water-damaged devices and can attempt to repair and salvage them.</p>
        </div>

        <div className="service-container">
          <h3>Component Level Repair</h3>
          <p>Our technicians are skilled in component level repair, meaning we can fix intricate issues on the motherboard or other internal components of the mobile phone.</p>
        </div>

        <div className="service-container">
          <h3>Software Troubleshooting</h3>
          <p>Problems with the software, such as freezing, crashing, or slow performance, can be frustrating. We offer software troubleshooting and repair to resolve these issues.</p>
        </div>

        <div className="service-container">
          <h3>Data Recovery</h3>
          <p>In cases where data is lost due to damage or software issues, we provide data recovery services to retrieve important files and information.</p>
        </div>

        <div className="service-container">
          <h3>Unlocking Services</h3>
          <p>If your phone is locked to a specific carrier, we can provide unlocking services, allowing you to use the device with any compatible network.</p>
        </div>

        <div className="service-container">
          <h3>Quality Parts and Warranties</h3>
          <p>We use only genuine and high-quality parts for repairs, and we back our services with warranties to ensure customer satisfaction and confidence.</p>
        </div>

        <div className="service-container">
          <h3>Accessories</h3>
          <p> In addition to repair services, we offer a range of mobile phone accessories, including cases, screen protectors, chargers, and more.</p>
        </div>

        <div className="service-container">
          <h3>Trade-In and Buyback</h3>
          <p>For customers looking to upgrade their phones, we offer trade-in and buyback services to provide fair value for their old devices.</p>
        </div>

        {/* Add other services in a similar manner */}
        {/* Remember to update the numbers accordingly */}
      </div>
    </div>
  );
};

export default OurServices;
