// AboutUs.js
import React from 'react';
import './AboutUs.css'; // Import the custom CSS file for AboutUs

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="section">
        <div className="section-left">
          <h2 className="section-heading">About Us</h2>
        </div>
        <div className="section-right">
          <p className="section-description">
          At MMD India Services Pvt Ltd, we take pride in being a trusted and dedicated partner in providing comprehensive after-sales services, efficient reverse and forward logistics solutions, and reliable warehousing services. With a commitment to excellence and a customer-centric approach, we have become a leading player in the industry, catering to the diverse needs of businesses across various sectors.
          </p>
        </div>
      </div>

      <div className="section">
      <div className="section-left">
        <h2 className="section-heading">Our Mission</h2>
        </div>
        <div className="section-right">
        <p className="section-description">
        Our mission is to enhance the post-sales experience for businesses and their customers alike by offering top-notch services tailored to their unique requirements. We strive to optimize supply chain operations, minimize downtime, and maximize customer satisfaction through our seamless solutions and professional expertise.
        </p>
        </div>
      </div>

      <div className="section">
        <div className="section-left">
          <h2 className="section-heading">What Sets Us Apart</h2>
        </div>
        <div className="section-right">
          <ul className="section-description">
            <li>Comprehensive After-Sales Services: We understand the importance of maintaining strong customer relationships even after the initial sale. Our after-sales services are designed to provide unparalleled support, ensuring your customers receive prompt assistance, technical support, and timely solutions, fostering long-term loyalty and satisfaction.</li>
            <li>Efficient Reverse Logistics: We specialize in managing the reverse flow of products, be it returns, repairs, or refurbishment. Our streamlined reverse logistics process helps you recover value from returned products while minimizing costs and environmental impact. From returns management to product refurbishment, we handle it all with precision and care.</li>
            <li>Seamless Forward Logistics: With our forward logistics solutions, we ensure your products reach their intended destinations swiftly and safely. From warehousing and inventory management to order fulfillment and last-mile delivery, we optimize every step of the supply chain for maximum efficiency and customer delight.</li>
            <li>State-of-the-Art Warehousing Facilities: Our warehousing services provide secure and strategically located storage facilities to safeguard your inventory. With advanced inventory tracking systems and climate-controlled storage options, you can trust us to handle your products with the utmost care and professionalism.</li>
          </ul>
        </div>
      </div>

      <div className="section">
      <div className="section-left">
        <h2 className="section-heading">Our Team</h2>
        </div>
        <div className="section-right">
        <p className="section-description">
        At MMD India Services Pvt Ltd, we have a team of highly skilled professionals who are passionate about what they do. Our experts bring years of experience in after-sales services, logistics, and warehousing, allowing us to provide you with tailor-made solutions that cater to your business needs effectively. With our customer-focused approach and industry insights, we strive to exceed your expectations and help you stay ahead in today's competitive market.
        </p>
        </div>
       
      </div>

      <div className="section">
        <div className="section-left">
          <h2 className="section-heading">Our Commitment</h2>
        </div>
        <div className="section-right">
          <ul className="section-description">
            <li>Reliability: We are committed to being a reliable partner you can count on at every step of the way. You can trust us to deliver on our promises and maintain the highest level of service quality.</li>
            <li>Transparency: We believe in fostering transparent and open communication with our clients. You will always have a clear understanding of our processes, costs, and progress, ensuring a smooth and hassle-free experience.</li>
            </ul>
        </div>
      </div>

      <div className="section">
      <div className="section-left">
        <h2 className="section-heading">Contact Us</h2>
        </div>
        <div className="section-right">
        <p className="section-description">
        Whether you require after-sales support, efficient reverse and forward logistics solutions, or secure warehousing services, MMD India Services Pvt Ltd is here to assist you. Reach out to our team today to discuss your needs, and let us partner with you to drive success and growth in your business.
        </p>
        </div>
       
      </div>
      {/* Add more subsections here as needed */}
    </div>
  );
};

export default AboutUs;
