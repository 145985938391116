// Members.js
import React from 'react';
import member1 from './member1.jpg';
import member2 from './member2.jpg';
import member3 from './member3.jpg';
import './Members.css'; // Import the custom CSS file for Members

const Members = () => {
  return (<>
    <h2 className="core-team-heading">Core Team</h2> {/* Adding the heading */}
      
    <div className="members-container">
      
      <div className="member">
        
        {/*<div className="member-image">
          <img src={member2} alt="Member 2" />
        </div>*/}
        <h3>Kingshuk Datta</h3>
        <p>Director</p>
      </div>
      <div className="member">
        {/*<div className="member-image">
          <img src={member1} alt="Member 1" />
        </div>*/}
        <h3>Sanjay Sultania</h3>
        <p>National Service Manager</p>
      </div>
      {/*<div className="member">
        <div className="member-image">
          <img src={member3} alt="Member 3" />
        </div>
        <h3>Arun Sharma</h3>
        <p>ASM North</p>
      </div>*/}
    </div>
    </>
  );
};

export default Members;
