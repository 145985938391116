// Navbar.js
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import the custom CSS file for Navbar

const CustomNavbar = () => {
  return (
    <Navbar bg="warning" variant="dark" expand="lg" sticky="top">
      <Navbar.Brand as={Link} to="/" className="logo">
        MMD India Services Pvt Ltd
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="nav-links">
          <Nav.Link as={Link} to="/" className="nav-item">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/members" className="nav-item">
            Members
          </Nav.Link>
          <Nav.Link as={Link} to="/aboutus" className="nav-item">
            About Us
          </Nav.Link>
          <Nav.Link as={Link} to="/ourservices" className="nav-item">
            Our Services
          </Nav.Link>
          <Nav.Link as={Link} to="/servicecenter" className="nav-item">
            Service Center 
          </Nav.Link>
          <Nav.Link as={Link} to="/contactus" className="nav-item">
            Contact Us 
          </Nav.Link>
          {/*<Nav.Link as={Link} to="/members" className="nav-item">
            Members
          </Nav.Link>
          <Nav.Link as={Link} to="/services" className="nav-item">
            Services
          </Nav.Link>
          <Nav.Link as={Link} to="/products" className="nav-item">
            Products
  </Nav.Link>*/}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
