// Home.js
import React from 'react';
import mmdLogo from './mmdlogo.jpg';
import './Home.css'; // Import the custom CSS file for Home
import realme from './realme.jpg';

const Home = () => {
  return (
    <div className="home-container">
      <div className="logo">
        <img src={mmdLogo} alt="MMD Logo" className="logo-image" />
      </div>

      <div className="banner">
      <br/>
      <br/>
        <h1>          Welcome to MMD India Services Pvt Ltd          </h1>
        <br/>
        <br/>
        <p>Your Trusted Partner for Mobile Repairs and Accessories</p>
        <br/>
        <br/>
      </div>

      <div className="content">
        <br/>
        <br/>
        <br/>
        <h2>About Our Company</h2>
        <br/>
        <p>
          At MMD Services Pvt Ltd, we take pride in providing top-notch mobile phone repairing services and offering a wide range of accessories. Our team of skilled technicians is dedicated to efficiently diagnosing and fixing various mobile phone issues, ensuring that our customers' devices are back in excellent working condition.
        </p>
        <p>
          We believe in delivering exceptional customer experiences, and our mission is to be the go-to mobile repair store for individuals and businesses alike. With a focus on quality, reliability, and affordability, we aim to exceed customer expectations and build long-lasting relationships.
        </p>
        <br/>
        <br/>
        <br/>
      </div>

      <div className="partner-section">
      <br/>
      <br/>
        <h2>Our Partner</h2>
        <br/>
        <br/>
        <div className="partner-logo">
          <img src={realme} alt="Realme Logo" className="partner-logo-image" />
        </div>
      </div>
    </div>
  );
};

export default Home;
