// ServiceCenter.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ServiceCenter.css';

const ServiceCenter = () => {
  const [serviceCenters, setServiceCenters] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://script.google.com/macros/s/AKfycbxqlQEWLIgbN9pC_QckRj5hUfT1xmmqSJayxrEwS-62ey0PlKkfNQkY9lrDmUHnWLaRzA/exec'
        );
        setServiceCenters(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="service-center-container">
      {serviceCenters.map((center) => (
        <div className="service-center" key={center.Name}>
          <div className="images">
            {/* You can leave this empty if there are no images */}
          </div>
          <h2>{center.Name}</h2>
          <p>{center.GoogleMapLocation}</p>
          <p>Phone: {center.PhoneNumber}</p>
        </div>
      ))}
    </div>
  );
};

export default ServiceCenter;
