// ContactUs.js
import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="contact-info">
        <p className="whatsapp">
          <span className="icon">📞</span>
          <a href="https://wa.me/+918910524124">WhatsApp</a>
        </p>
        <p className="email">
          <span className="icon">📧</span>
          <a href="mailto:info@mmdindia.net">Email</a>
        </p>
        <p>Feel free to reach out to us for any inquiries, assistance, or collaboration opportunities.</p>
      </div>
    </div>
  );
};

export default ContactUs;
