// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CustomNavbar from './components/Navbar';
import Home from './components/Home';
import Members from './components/Members';
import AboutUs from './components/AboutUs';
import OurServices from './components/OurServices';
import Footer from './components/Footer';
import ServiceCenter from './components/ServiceCenter';
import ContactUs from './components/ContactUs';


function App() {
  return (
    <Router>
      <div>
        <CustomNavbar />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/members" element={<Members/>} />
          <Route path="/aboutus" element={<AboutUs/>} />
          <Route path="/ourservices" element={<OurServices/>} />
          <Route path="/servicecenter" element={<ServiceCenter/>} />
          <Route path="/contactus" element={<ContactUs/>} />
          {/* Add other routes for services, products, etc. here */}
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
